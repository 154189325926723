import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";

const PrivacyPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => (
  <Layout>
    <div className="container mx-auto d-block pt-5 pb-5">
      <div className="row">
        <div className="col-12 offset-0 col-lg-8 offset-lg-2">
          {edges.map(({ node: { html } }) => (
            <div dangerouslySetInnerHTML={{ __html: html }} />
          ))}
        </div>
      </div>
    </div>
  </Layout>
);
export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "datenschutz" } } }
      limit: 1
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            slug
          }
        }
      }
    }
  }
`;

export default PrivacyPage;
